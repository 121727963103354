<template>
  <div class="placeWrap">
    <div class="typesData">
      <div class="placeText">
        <img
          v-lazy="require('@/assets/images/salesHead.png')"
          class="salesHead"
        />
        <div>The MetaRace Genesis Horses are divided into four breeds,</div>
        <div>
          with a number of each breed to be released for purchase in the first
          phase:
        </div>
      </div>
      <div class="typeHorse">
        <div class="horseItem" v-for="(item, index) in horseType" :key="index">
          <div class="horseDec">
            <div class="horseName">{{ item.name }}</div>
            <div class="attrs">{{ item.attrs }}</div>
            <div class="rateNum">
              <div
                v-for="(val, idx) in item.rateOn"
                :key="'rateOn' + idx"
                class="rateImg"
              />
              <template v-if="item.rateOff > 0">
                <div
                  v-for="(val, idx) in item.rateOff"
                  :key="'rateOff' + idx"
                  class="rateImgOff"
                />
              </template>
            </div>
            <div class="price">
              First round release:
              <p>{{ item.totalSupply }}</p>
            </div>
            <div class="price now">
              Only this many <br />
              remaining:
              <p>{{ item.reminder }}</p>
            </div>
            <div class="btn" @click="buy(item.name)">SNAP UP NOW</div>
          </div>
          <div class="hourseImg">
            <div class="usdtImg">{{item.price}} <br />USDT</div>
            <img :src="item.imgs" alt="" class="hourseType" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import MetaRaceSDK from './../../utils/MetaRaceSDK'

export default {
  data() {
    return {
      horseType: [
        {
          name: "DARLEY ARABIAN",
          attrs: "6 Finest Genetic Attributes",
          totalSupply: 0,
          reminder: 0,
          price:0,
          rateOn: 6,
          rateOff: 0,
          imgs: require("../../assets/images/DARLEY.png"),
        },
        {
          name: "GODOLPHIN ARABIAN",
          attrs: "4 Finest Genetic Attributes",
          totalSupply: 0,
          reminder: 0,
          price:0,
          rateOn: 5,
          rateOff: 1,
          imgs: require("../../assets/images/BYERLY.png"),
        },
        {
          name: "BYERLY TURK",
          attrs: "5 Finest Genetic Attributes",
          totalSupply: 0,
          reminder: 0,
          price:0,
          rateOn: 4,
          rateOff: 2,
          imgs: require("../../assets/images/GODOLPHIN.png"),
        },
        {
          name: "NAKAMOTO SATOSHI",
          attrs: "3 Finest Genetic Attributes",
          totalSupply: 0,
          reminder: 0,
          price:0,
          rateOn: 3,
          rateOff: 3,
          imgs: require("../../assets/images/SATOSHI.png"),
        },
      ],
    };
  },
  created(){
    this.initData()
  },
  methods: {
    changeGif(name) {
      this.gifName = name;
    },
    async initData() {
      let {account, env, chainId, msg} = await MetaRaceSDK.CheckStatus()
      if(account && typeof(msg) === 'string'){
        this.$message.error(msg);
        return false
      }else{
        this.horseType.map(async (item, index) => {
          let totalSupply = await MetaRaceSDK.GetNftTotalSupply(this.horseType[index].name.replace(' ', '_'))
          let reminder = await MetaRaceSDK.GetNftReminder(this.horseType[index].name.replace(' ', '_'))
          let price = await MetaRaceSDK.GetNftPrice(this.horseType[index].name.replace(' ', '_'))
          if(!totalSupply.code) this.horseType[index].totalSupply = totalSupply
          if(!reminder.code) this.horseType[index].reminder = reminder
          if(!price.code) this.horseType[index].price = Number(price/10000)
        })
      }
    },
    buy(type) {
      let that = this
      MetaRaceSDK.Buy(type.replace(' ', '_'), (res)=>{
        console.log(res)
        if(res.code === 1){
          this.$message({
              message: "Success!",
              type: "success",
          });
          that.initData()
        }
      })
    }
  },
};
</script>


<style lang="scss" scoped>
.placeWrap {
  width: 100%;
  // height: 752px;
  background: #000;

  .typesData {
    width: 1200px;
    margin: 0 auto;
    padding-top: 51px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: "Rubik-BoldItalic";
    .placeText {
      .salesHead {
        width: 613px;
        height: 176px;
        margin-bottom: 19px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      font-family: "Rubik-MediumItalic";
    }
    .breeds {
      width: 968px;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      font-family: "Rubik-MediumItalic";
    }
    .typeHorse {
      margin-top: 69px;
      margin-bottom: 47px;
      display: flex;
      flex-wrap: wrap;
      .horseItem {
        width: 48%;
        height: 450px;
        border-radius: 15px;
        padding: 26px 27px 24px 30px;
        box-sizing: border-box;
        margin-bottom: 68px;
        margin-right: 33px;
        display: flex;
        background: #300856;
        position: relative;
        z-index: 999;

        .hourseImg {
          position: absolute;
          top: -21px;
          right: 0;
          .hourseType {
            width: 358px;
            height: 370px;
            position: relative;
            top: 80px;
            right: 13px;
            z-index: 99;
          }
          .usdtImg {
            width: 150px;
            height: 243px;
            background: url("../../assets/images/usdtBg.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            right: 27px;
            text-align: center;
            font-family: "Rubik-BoldItalic";
            font-size: 38px;
            padding-top: 44px;
          }
        }
        .horseDec {
          display: flex;
          flex-direction: column;
          .horseName {
            width: 272px;
            font-size: 45px;
          }
          .attrs {
            font-size: 12px;
            font-family: "Rubik-MediumItalic";
            margin-top: 28px;
            margin-bottom: 12px;
          }
          .rateNum {
            display: flex;
            margin-bottom: 17px;
            .rateImg,
            .rateImgOff {
              width: 28px;
              height: 26px;
              background: url("../../assets/images/rateOn.png") no-repeat;
              background-size: 100% 100%;
            }
            .rateImgOff {
              background: url("../../assets/images/rateOff.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          .price {
            color: #ff00ea;
            font-size: 12px;
            line-height: 1.5;
            p {
              color: #fff;
              font-size: 27px;
            }
          }
          .now {
            p {
              color: #eda100;
            }
          }
          .btn {
            width: 151px;
            height: 37px;
            background: #ff00ea;
            border-radius: 10px;
            margin-top: 27px;
            font-size: 18px;
            text-align: center;
            line-height: 37px;
            cursor: pointer;
          }
        }
      }
      .horseItem:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
